import { useEffect, useState } from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import {
	array,
	bool,
	func,
	number,
	object,
	oneOfType,
	string,
} from 'prop-types';

import { UiInput } from 'components/Form';

import './Select.scss';

const SelectField = ({
	id,
	name,
	label = '',
	placeholder = '',
	description = '',
	value = '',
	required = false,
	focused,
	setFocused,
	touched,
	setTouched,
	readOnly,
	disabled = false,
	errors = [],
	onChange,
	className,
	options = [],
	isClearable = true,
	isCreatable = false,
	isMulti = false,
}) => {
	const SelectComponent = isCreatable ? Creatable : Select;

	const [opt, setOpt] = useState(
		options.map(x => (typeof x === 'string' ? { value: x, label: x } : x)),
	);

	useEffect(() => {
		if (opt.length === 0 && options.length)
			setOpt(
				options.map(x =>
					typeof x === 'string' ? { value: x, label: x } : x,
				),
			);
	}, [options, opt]);

	return (
		<UiInput
			id={id}
			label={label}
			description={description}
			required={required}
			focused={focused}
			disabled={disabled || readOnly}
			className={className}
			hasValue={value.length > 0}
			hasError={errors.length > 0 && touched ? true : false}
			errors={errors}>
			<SelectComponent
				className='select'
				id={id}
				name={name}
				classNamePrefix='select'
				options={opt}
				isMulti={isMulti}
				closeMenuOnSelect={!isMulti}
				isClearable={isClearable}
				value={
					isMulti
						? opt
								.flatMap(group => group?.options || group)
								.filter(option => value.includes(option.value))
						: opt
								.flatMap(group => group?.options || group)
								.find(option => option.value === value)
				}
				onChange={(option, actionMeta) => {
					if (actionMeta.action === 'clear') {
						onChange('');
						return;
					}

					if (isMulti) {
						onChange(
							option.map(option => {
								if (option.__isNew__) {
									setOpt([
										...opt,
										{
											label: option.label,
											value: option.value,
										},
									]);
								}

								return option.value;
							}),
						);
					} else {
						if (option.__isNew__) {
							setOpt([
								...opt,
								{ label: option.label, value: option.value },
							]);
						}

						onChange(option.value);
					}
				}}
				onBlur={() => {
					if (setTouched) setTouched(true);
					if (setFocused) setFocused(false);
				}}
				onFocus={() => {
					if (setFocused) setFocused(true);
				}}
				placeholder={placeholder}
				isDisabled={disabled}
				styles={{
					menuPortal: base => ({
						...base,
						zIndex: 9999999,
						marginLeft: '-0.5rem',
					}),
				}}
				menuPortalTarget={document.getElementById('ketchup-base')}
				menuPosition='absolute'
			/>
		</UiInput>
	);
};

SelectField.propTypes = {
	name: string.isRequired,
	label: string,
	placeholder: string,
	description: string,
	value: oneOfType([string, number, array, object]),
	required: bool,
	disabled: bool,
	errors: array,
	onChange: func.isRequired,
	options: array,
	isMulti: bool,
	isClearable: bool,
	isCreatable: bool,
	returnOptionsObj: bool,
};

export default SelectField;
