import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import ContentState from 'draft-js/lib/ContentState';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { array, bool, element, func, oneOfType, string } from 'prop-types';

import { UiInput } from 'components/Form';

import { toolbar } from './toolbar.helper';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TinyEditor.scss';

const TinyEditor = ({
	id,
	label = '',
	placeholder = '',
	description = '',
	value = '',
	required = false,
	focused = false,
	setFocused,
	touched = false,
	setTouched,
	readOnly = false,
	disabled = false,
	errors = [],
	onChange,
	className,
}) => {
	const [editorState, setEditorState] = useState(
		!value
			? EditorState.createEmpty()
			: EditorState.createWithContent(
					ContentState.createFromBlockArray(htmlToDraft(value)),
				),
	);

	const onEditorStateChange = async editorState => {
		await setEditorState(editorState);
		const data = convertToRaw(editorState.getCurrentContent());

		onChange(draftToHtml(data));
	};

	useEffect(() => {
		setEditorState(!value ? EditorState.createEmpty() : editorState);
	}, [value]);

	return (
		<UiInput
			id={id}
			label={label}
			description={description}
			required={required}
			focused={focused}
			disabled={disabled || readOnly}
			className={className}
			hasValue={editorState.getCurrentContent().getPlainText().length > 0}
			hasError={errors.length > 0 && touched ? true : false}
			errors={errors}>
			<Editor
				editorState={editorState}
				placeholder={`${placeholder}${
					placeholder && !required && !label ? ' (opt.)' : ''
				}`}
				onEditorStateChange={onEditorStateChange}
				onBlur={() => {
					setTouched(true);
					setFocused(false);
				}}
				onFocus={() => setFocused(true)}
				toolbar={toolbar}
				wrapperClassName='editor__wrapper'
				editorClassName='editor__content'
				toolbarClassName='editor__toolbar'
				readOnly={disabled || readOnly}
			/>
		</UiInput>
	);
};

TinyEditor.propTypes = {
	id: string,
	label: string,
	placeholder: string,
	description: oneOfType([string, element]),
	value: string,
	className: string,
	required: bool,
	focused: bool,
	setFocused: func,
	touched: bool,
	setTouched: func,
	readOnly: bool,
	disabled: bool,
	errors: array,
	onChange: func.isRequired,
};

export default TinyEditor;
