import { forwardRef, useEffect, useRef, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnlineStatus } from 'core/hooks/OnlineStatusProvider';
import { useTheme } from 'core/hooks/ThemeProvider';
import {
	bool,
	func,
	node,
	number,
	object,
	oneOfType,
	string,
} from 'prop-types';

import CardBody from './Body/CardBody';
import CardFooter from './CardFooter/CardFooter';
import CardHeader from './CardHeader/CardHeader';

import 'react-loading-skeleton/dist/skeleton.css';
import './Card.scss';

const Card = forwardRef(
	(
		{
			title,
			subTitle,
			url,
			meta,
			icon,
			footer,
			folded = false,
			withReadMore = false,
			showArrow = false,
			readMoreText = 'Lees meer',
			useOverflow = false,
			children,
			withShadow = false,
			useFunc,
			className,
			loading = false,
			skeletonCount = 1,
			extraFooterClass,
			maxHeight = 0,
		},
		ref,
	) => {
		const online = useOnlineStatus();
		const { theme } = useTheme();

		const [isFolded, setIsFolded] = useState(folded ?? true);
		const [hasOverflow, setHasOverflow] = useState(true);

		const cardBody = useRef();

		useEffect(() => {
			if (useOverflow)
				setHasOverflow(
					!isFolded ||
						cardBody.current.offsetHeight <
							cardBody.current.scrollHeight,
				);
		}, [hasOverflow, useOverflow]);

		return (
			<SkeletonTheme
				baseColor={theme === 'dark' ? '#1A1217' : '#FAFAFA'}
				highlightColor={
					theme === 'dark'
						? 'rgba(65, 47, 59, .4)'
						: 'rgba(131, 94, 130, .2)'
				}>
				<div
					className={`card${withShadow ? ' shadow' : ''}${withReadMore ? ' with-read-more' : ''}${
						className ? ` ${className}` : ''
					}`}
					ref={ref}>
					<div className='card__content'>
						<div
							className='card__content_wrapper'
							style={maxHeight ? { maxHeight: maxHeight } : {}}>
							{icon ? (
								<div className='card__content_wrapper__background'>
									<FontAwesomeIcon icon={icon} />
								</div>
							) : null}
							{title || icon || subTitle || meta ? (
								<CardHeader
									title={title}
									subTitle={subTitle}
									url={url ? url : null}
									meta={meta}
									loading={loading}
									withReadMore={withReadMore}
									showArrow={showArrow}
									isFolded={isFolded}
									setFolded={() => setIsFolded(!isFolded)}
								/>
							) : null}

							<CardBody
								withReadMore={withReadMore}
								isFolded={isFolded}
								ref={cardBody}>
								{!loading ? (
									children
								) : online ? (
									<Skeleton count={skeletonCount} />
								) : (
									'Item kan niet worden geladen.'
								)}
							</CardBody>

							{withReadMore && hasOverflow ? (
								<button
									className='card__read-more'
									onClick={
										useFunc !== undefined
											? useFunc
											: () => setIsFolded(!isFolded)
									}
									aria-label='lees meer'>
									<FontAwesomeIcon
										icon={
											isFolded
												? faChevronDown
												: faChevronUp
										}
									/>{' '}
									{readMoreText}
								</button>
							) : null}
						</div>
					</div>

					{footer ? (
						<CardFooter
							className={
								extraFooterClass ? extraFooterClass : ''
							}>
							{footer}
						</CardFooter>
					) : null}
				</div>
			</SkeletonTheme>
		);
	},
);

Card.displayName = 'Card';

Card.propTypes = {
	title: oneOfType([string, object]),
	subTitle: string,
	url: string,
	meta: string,
	icon: object,
	footer: object,
	folded: bool,
	withReadMore: bool,
	showArrow: bool,
	readMoreText: string,
	useOverflow: bool,
	children: node,
	withShadow: bool,
	className: string,
	loading: bool,
	skeletonCount: number,
	useFunc: func,
	extraFooterClass: string,
	maxHeight: number,
};

export default Card;
