import { array, bool, func, number, oneOfType, string } from 'prop-types';

import { UiInput } from 'components/Form';

import './InputCheckbox.scss';

const InputCheckbox = ({
	type = 'checkbox',
	id,
	name,
	label,
	groupLabel,
	description = '',
	value = false,
	required = false,
	touched = false,
	setTouched,
	readOnly = false,
	disabled = false,
	errors = [],
	onChange,
	className,
	style,
}) => {
	return (
		<UiInput
			id={id}
			label={groupLabel}
			description={description}
			required={required}
			disabled={disabled || readOnly}
			className={className}
			hasValue={!!value}
			hasError={errors.length > 0 && touched ? true : false}
			errors={errors}
			style={style}
			checkbox>
			<label>
				<input
					name={name}
					type={type}
					id={id}
					checked={value}
					onChange={() => {
						if (onChange) onChange(!value);
						if (setTouched) setTouched(true);
					}}
					readOnly={readOnly}
					disabled={disabled}
				/>
				<span>{label}</span> {required ? <code>(verpl.)</code> : null}{' '}
			</label>
		</UiInput>
	);
};

InputCheckbox.propTypes = {
	id: string,
	type: string.isRequired,
	name: string.isRequired,
	label: string,
	groupLabel: string,
	description: string,
	value: oneOfType([bool, string, number]),
	className: string,
	required: bool,
	touched: bool,
	setTouched: func,
	readOnly: bool,
	disabled: bool,
	errors: array,
	onChange: func.isRequired,
};

export default InputCheckbox;
