import { mainReducer } from 'core/store/mainReducer';
import { applyMiddleware, compose, createStore } from 'redux';
import { thunk } from 'redux-thunk';

export let composeEnchancers;
export let store;

const getPersistedState = () => {
	const remember = localStorage.getItem('rememberMe');
	let obj = {};

	if (remember) {
		Object.keys(localStorage)
			.filter(x => x.includes('reducer'))
			.forEach(name => {
				const array = name.split('.');
				obj[array[1]] = JSON.parse(localStorage[name]);
			});
	}

	return obj;
};

const setStore = state => {
	if (process.env.REACT_APP_ENVIRONMENT === 'production') {
		store = createStore(mainReducer, state, applyMiddleware(thunk));
	} else {
		composeEnchancers =
			window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
		store = createStore(
			mainReducer,
			state,
			composeEnchancers(applyMiddleware(thunk)),
		);
	}
};

setStore(getPersistedState());
