import { array, bool, node, object, oneOfType, string } from 'prop-types';

const UiInput = props => {
	const {
		id,
		label = '',
		description = '',
		required = false,
		focused = false,
		disabled = false,
		className = '',
		hasValue = false,
		hasError = false,
		errors = [],
		children,
		checkbox = false,
		radio = false,
		toggle = false,
		isFileUpload = false,
		hideLayout = false,
		style,
	} = props;
	return (
		<div
			className={`form-${checkbox ? 'checkbox' : radio ? 'radio' : toggle ? 'toggle' : 'field'}${focused ? ' form-field--focused' : ''}${
				hasValue && !radio ? ' form-field--has-value' : ''
			}${hasError ? ` form-field--has-errors` : ''}${
				className ? ` ${className}` : ''
			}${disabled ? ` form-field--disabled` : ''}${hideLayout ? ` form-field--hide-layout` : ''}`}
			style={style}>
			{label ? (
				<label htmlFor={id}>
					<span>{label}</span> {required ? null : <code>(opt.)</code>}{' '}
				</label>
			) : null}

			<div
				className={`form-${checkbox ? 'checkbox' : radio ? 'radio' : toggle ? 'toggle' : 'field'}__control${isFileUpload ? ' form-field__upload' : ''}`}>
				{children}
			</div>
			{hasError ? (
				<div className='form-field__invalid-feedback'>
					{errors.map((x, i) => {
						return <div key={i}>{x}</div>;
					})}
				</div>
			) : (
				<div className='form-field__description'>{description}</div>
			)}
		</div>
	);
};

UiInput.propTypes = {
	id: string.isRequired,
	label: string,
	description: oneOfType([string, object]),
	required: bool,
	focused: bool,
	disabled: bool,
	className: string,
	hasValue: bool,
	hasError: bool,
	errors: array,
	children: node,
	checkbox: bool,
	radio: bool,
	toggle: bool,
	isFileUpload: bool,
	hideLayout: bool,
};

export default UiInput;
