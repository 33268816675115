import { useMemo } from 'react';
import {
	faChampagneGlasses,
	faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { number, string } from 'prop-types';

const StatisticTwo = ({ type, year, count = 0 }) => {
	const content = useMemo(() => {
		let singular, multiple, icon;

		switch (type) {
			case 'events':
				singular = 'evenement';
				multiple = 'evenementen';
				icon = faChampagneGlasses;
				break;

			case 'trainings':
				singular = 'opleiding';
				multiple = 'opleidingen';
				icon = faGraduationCap;
				break;

			case 'news':
			default:
				singular = 'bericht';
				multiple = 'berichten';
				icon = faGraduationCap;
				break;
		}

		return {
			singular,
			multiple,
			icon,
		};
	}, []);

	return (
		<div className='static__two'>
			<div className='static__two--logo'>
				<FontAwesomeIcon icon={content.icon} fixedWidth />
			</div>
			<div className='static__two--content'>
				<h3>
					Aantal {content.multiple} in {year}
				</h3>
				{count} {count === 1 ? content.singular : content.multiple}
			</div>
		</div>
	);
};

StatisticTwo.propTypes = {
	type: string.isRequired,
	year: number.isRequired,
	count: number.isRequired,
};

export default StatisticTwo;
