import { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import { fr, nl } from 'date-fns/locale';
import {
	array,
	arrayOf,
	bool,
	func,
	oneOf,
	oneOfType,
	string,
} from 'prop-types';

import { UiInput } from 'components/Form';

import './Datepicker.scss';

const locales = {
	fr,
	nl,
};

const Datepicker = ({
	id,
	label,
	mode = 'single',
	description,
	value,
	required = false,
	focused,
	touched,
	setTouched,
	readOnly,
	disabled,
	errors,
	onChange,
	className,
	disabledDays = [],
	locale = 'nl',
}) => {
	const [month, setMonth] = useState(new Date());

	const emptyRange = { from: null, to: null };

	useEffect(() => {
		const date =
			value?.length > 0 ? new Date(value[value.length - 1]) : new Date();
		if (date.getMonth() !== month.getMonth()) {
			setMonth(date);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const createSelected = () => {
		if (mode === 'range') {
			if (!value || value.length === 0) {
				return emptyRange;
			}

			return {
				from: new Date(new Date(value[0]).setHours(0)),
				to: new Date(new Date(value[value.length - 1]).setHours(0)),
			};
		}
		return value.map(date => new Date(date));
	};

	const selected = createSelected();

	const parseRange = range => {
		if (!range) {
			if (selected.from.toISOString() !== selected.to.toISOString()) {
				return [format(selected.from, 'yyyy-MM-dd')];
			}
			return [];
		}

		const dates = [];
		const startDate = range.from;
		const endDate = range.to;

		if (startDate) {
			dates.push(format(startDate, 'yyyy-MM-dd'));
			if (endDate) {
				while (startDate.getTime() < endDate.getTime()) {
					startDate.setDate(startDate.getDate() + 1);
					dates.push(format(startDate, 'yyyy-MM-dd'));
				}
			}
		}
		return dates;
	};

	const handleSelect = selection => {
		setTouched(true);
		if (mode === 'single') {
			onChange(selection ? [format(selection, 'yyyy-MM-dd')] : []);
		} else if (mode === 'multiple') {
			onChange(selection.map(date => format(date, 'yyyy-MM-dd')));
		} else if (mode === 'range') {
			const dates = parseRange(selection);
			onChange(dates);
		}
	};

	return (
		<UiInput
			id={id}
			label={label}
			description={description}
			required={required}
			focused={focused}
			disabled={disabled || readOnly}
			className={className}
			hasValue={selected?.length > 0}
			hasError={errors.length > 0 && touched ? true : false}
			errors={errors}
			hideLayout>
			<DayPicker
				selected={selected}
				onSelect={handleSelect}
				locale={locales[locale]}
				modifiers={{
					weekend: { dayOfWeek: [0, 6] },
				}}
				month={month}
				onMonthChange={setMonth}
				mode={mode}
				//required={required}
				disabled={disabled ?? disabledDays}
				modifiersClassNames={{
					weekend: 'rdp-day_weekend',
				}}
			/>
		</UiInput>
	);
};

Datepicker.propTypes = {
	required: bool,
	value: arrayOf(string),
	onChange: func,
	mode: oneOf(['single', 'multiple', 'range']),
	className: string,
	disabledDays: oneOfType([array, bool]),
	locale: oneOf(['fr', 'nl']),
};

export default Datepicker;
