import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	faBan,
	faCheck,
	faList,
	faUserGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { seniority } from 'core/helpers/entities/entity.helper';
import {
	loadCoces,
	loadDepartments,
} from 'core/store/application/application.actions';
import { loadEmployees } from 'core/store/employees/employee.actions';
import { array, bool, string } from 'prop-types';

import { Button, InputField } from 'components/Form';
import Modal from 'components/Modal/Modal';

import './UserSelector.scss';

export const UserSelector = ({
	selected = [],
	setSelected,
	title,
	hideShortcuts = false,
}) => {
	const dispatch = useDispatch();

	const { employees } = useSelector(state => state.employeeReducer);
	const { departments, coces } = useSelector(
		state => state.applicationReducer,
	);

	const [unselected, setUnselected] = useState([]);
	const [searchTag, setSearchTag] = useState('');
	const [show, setShow] = useState(false);

	useEffect(() => {
		dispatch(loadEmployees());
		dispatch(loadCoces());
		dispatch(loadDepartments());
	}, []);

	useEffect(() => {
		setUnselected(
			employees.filter(
				employee =>
					!selected.some(
						selectedEmployee => selectedEmployee.id === employee.id,
					),
			),
		);
	}, [selected, employees]);

	useEffect(() => {
		setSelected(
			selected
				.filter(x => employees.some(y => y.id === x.id))
				.map(x => {
					const employee = employees.find(e => e.id === x.id);
					return {
						id: employee?.id,
						firstName: employee.firstName,
						lastName: employee.lastName,
						department: employee.department,
						coces: employee.coces,
						seniority: employee.seniority,
						confirmed: x.confirmed ?? null,
					};
				}),
		);
	}, []);

	const selectAll = () => {
		set(
			employees.map(employee => ({
				id: employee.id,
				firstName: employee.firstName,
				lastName: employee.lastName,
				department: employee.department,
				coces: employee.coces,
				seniority: employee.seniority,
				confirmed: employee.confirmed ?? null,
			})),
		);
	};

	const unselectAll = () => {
		set([]);
	};

	const selectDepartment = (name, add) => {
		if (add) {
			set([
				...selected,
				...employees
					.filter(employee => employee.department === name)
					.map(employee => ({
						id: employee.id,
						firstName: employee.firstName,
						lastName: employee.lastName,
						department: employee.department,
						coces: employee.coces,
						seniority: employee.seniority,
						confirmed: employee.confirmed ?? null,
					})),
			]);
		} else {
			set([
				...selected
					.filter(employee => employee.department !== name)
					.map(employee => ({
						id: employee.id,
						firstName: employee.firstName,
						lastName: employee.lastName,
						department: employee.department,
						coces: employee.coces,
						seniority: employee.seniority,
						confirmed: employee.confirmed ?? null,
					})),
			]);
		}
	};

	const selectCoce = (name, add) => {
		if (add) {
			set([
				...selected,
				...employees
					.filter(employee => employee.coces.includes(name))
					.map(employee => ({
						id: employee.id,
						firstName: employee.firstName,
						lastName: employee.lastName,
						department: employee.department,
						coces: employee.coces,
						seniority: employee.seniority,
						confirmed: employee.confirmed ?? null,
					})),
			]);
		} else {
			set([
				...selected
					.filter(employee => !employee.coces.includes(name))
					.map(employee => ({
						id: employee.id,
						firstName: employee.firstName,
						lastName: employee.lastName,
						department: employee.department,
						coces: employee.coces,
						seniority: employee.seniority,
						confirmed: employee.confirmed ?? null,
					})),
			]);
		}
	};

	const selectSeniority = (min, max, add) => {
		if (add) {
			set([
				...selected,
				...employees
					.filter(
						employee =>
							employee.seniority <= max &&
							employee.seniority >= min,
					)
					.map(employee => ({
						id: employee.id,
						firstName: employee.firstName,
						lastName: employee.lastName,
						department: employee.department,
						coces: employee.coces,
						seniority: employee.seniority,
						confirmed: employee.confirmed ?? null,
					})),
			]);
		} else {
			set([
				...selected
					.filter(
						employee =>
							employee.seniority > max ||
							employee.seniority < min,
					)
					.map(employee => ({
						id: employee.id,
						firstName: employee.firstName,
						lastName: employee.lastName,
						department: employee.department,
						coces: employee.coces,
						seniority: employee.seniority,
						confirmed: employee.confirmed ?? null,
					})),
			]);
		}
	};

	const handleAdd = id => {
		const employee = employees.find(x => x.id === id);
		set([
			...selected,
			{
				id: employee.id,
				firstName: employee.firstName,
				lastName: employee.lastName,
				department: employee.department,
				coces: employee.coces,
				seniority: employee.seniority,
				confirmed: employee.confirmed ?? null,
			},
		]);
	};

	const handleRemove = id => {
		set([...selected.filter(x => x.id !== id)]);
	};

	const set = array => {
		const setObj = Object.values(
			array.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {}),
		);
		setSelected(
			Array.from(setObj).sort((a, b) =>
				a.firstName.localeCompare(b.firstName),
			),
		);
	};

	return (
		<section className='user-selector'>
			<section className='user-selector__select'>
				<label>{title} uitnodigen</label>
				{!hideShortcuts ? (
					<section className='user-selector__select--shortcut'>
						<div className='btn__group full--flex'>
							<Button
								type='button'
								buttonStyle='primary'
								onClick={() => setShow(!show)}
								label={<FontAwesomeIcon icon={faList} />}
								disabled={unselected.length === 0}
							/>
							<Button
								type='button'
								buttonStyle='primary'
								onClick={selectAll}
								label={<FontAwesomeIcon icon={faUserGroup} />}
							/>
							<Button
								type='button'
								buttonStyle='primary'
								onClick={unselectAll}
								label={<FontAwesomeIcon icon={faBan} />}
								disabled={selected.length === 0}
							/>
						</div>
						<div className='btn__group full--flex'>
							{departments.map(({ name, id }) => {
								const active = unselected.some(
									employee => employee.department === name,
								);

								return (
									<Button
										type='button'
										buttonStyle={
											active ? 'secondary' : 'primary'
										}
										buttonSize='small'
										onClick={() =>
											selectDepartment(name, active)
										}
										label={name}
										key={id}
									/>
								);
							})}
						</div>
						<div className='btn__group full--flex'>
							{coces.map(({ name, id }) => {
								const active = unselected.some(employee =>
									employee.coces.includes(name),
								);

								return (
									<Button
										type='button'
										buttonStyle={
											active ? 'secondary' : 'primary'
										}
										buttonSize='small'
										onClick={() => selectCoce(name, active)}
										label={name === 'AND' ? 'STAF' : name}
										key={id}
									/>
								);
							})}
						</div>

						<div className='btn__group full--flex'>
							{seniority.map(({ name, id, min, max }) => {
								const active = unselected.some(
									employee =>
										employee.seniority <= max &&
										employee.seniority >= min,
								);

								return (
									<Button
										type='button'
										buttonStyle={
											active ? 'secondary' : 'primary'
										}
										buttonSize='small'
										onClick={() =>
											selectSeniority(min, max, active)
										}
										label={name}
										key={id}
									/>
								);
							})}
						</div>
					</section>
				) : null}
				<InputField
					id='search'
					name='search'
					value={searchTag}
					placeholder='Zoek op naam'
					setFocused={() => ''}
					setTouched={() => ''}
					onChange={value => setSearchTag(value)}
					required
				/>
				<div className='user-selector__select--dropdown'>
					{searchTag
						? employees
								.filter(empleyee =>
									`${empleyee.firstName} ${empleyee.lastName}`
										.toLowerCase()
										.includes(searchTag.toLowerCase()),
								)
								.map(employee => {
									const active = selected.find(
										x => x.id === employee.id,
									);

									return (
										<section
											key={employee.id}
											className={`user-selector__select--dropdown--item${active ? ' selected' : ''}`}
											onClick={() => {
												active
													? handleRemove(employee.id)
													: handleAdd(employee.id);
											}}>
											{employee.firstName}{' '}
											{employee.lastName}
											{active ? (
												<FontAwesomeIcon
													icon={faCheck}
												/>
											) : null}
										</section>
									);
								})
						: null}
				</div>
				{selected.length ? (
					<div className='btn__group full--flex'>
						<Link
							to='registraties'
							className='btn btn--small btn--secondary btn--block'>
							Inschrijvingen
						</Link>
						<Link
							to='aanwezigheden'
							className='btn btn--small btn--secondary btn--block'>
							Aanwezigheden
						</Link>
					</div>
				) : (
					''
				)}
			</section>

			<section className='user-selector__selected'>
				<label>
					Geselecteerde {title.toLowerCase()}{' '}
					<span>({selected?.length})</span>
				</label>
				{selected.length ? (
					<section className='user-selector__selected--container'>
						{selected?.map(employee => {
							return (
								<div
									key={employee.id}
									className={`user-selector__selected--container--item${(employee?.confirmed ?? null) !== null ? (employee.confirmed ? ' accepted' : ' declined') : ''}`}
									onClick={() => handleRemove(employee.id)}>
									{employee.firstName} {employee.lastName}
								</div>
							);
						})}
					</section>
				) : (
					<div
						className='empty-list'
						style={{ marginBottom: 0, marginTop: '5rem' }}>
						Nog geen {title.toLowerCase()} uitgenodigd
					</div>
				)}
			</section>

			<Modal show={show} close={() => setShow(false)}>
				<section className='add-employee'>
					{departments.map(({ name }) => (
						<div key={name}>
							<h2>{name}</h2>
							<div className='add-employee__container'>
								{unselected
									.filter(
										employee =>
											employee.department === name,
									)
									.map(item => (
										<div
											className='add-employee__container--item'
											onClick={() => handleAdd(item.id)}
											key={item.id}>
											{item.firstName} {item.lastName}
										</div>
									))}
							</div>
						</div>
					))}
				</section>
			</Modal>
		</section>
	);
};

UserSelector.prototype = {
	title: string.isRequired,
	selected: array.isRequired,
	hideShortcuts: bool,
};
