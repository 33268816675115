import {
	array,
	arrayOf,
	bool,
	func,
	number,
	oneOfType,
	shape,
	string,
} from 'prop-types';

import { UiInput } from 'components/Form';

import './InputRadioButton.scss';

export const InputRadioButton = ({
	type = 'radio',
	id,
	name,
	label = '',
	description = '',
	value,
	required = false,
	touched = false,
	setTouched,
	readOnly = false,
	disabled = false,
	errors = [],
	onChange,
	className,
	options,
}) => {
	return (
		<UiInput
			id={id}
			label={label}
			description={description}
			required={required}
			disabled={disabled || readOnly}
			className={className}
			hasValue={!!value}
			hasError={errors?.length > 0 && touched ? true : false}
			errors={errors}
			radio>
			{options
				.filter(option => !option.hide)
				.map(option => {
					const opt =
						typeof option === 'string'
							? { value: option, label: option }
							: option;

					return (
						<label
							htmlFor={`${id}-${opt.value}`}
							key={opt.value}
							className={
								value === opt.value
									? 'form-field--has-value'
									: ''
							}>
							<input
								name={name}
								type={type}
								id={`${id}-${opt.value}`}
								value={opt.value}
								checked={value === opt.value}
								onChange={() => {
									if (onChange) onChange(opt.value);
									if (setTouched) setTouched(true);
								}}
								readOnly={readOnly}
								disabled={opt.disabled || disabled}
							/>
							<span>{opt.label}</span>
						</label>
					);
				})}
		</UiInput>
	);
};

InputRadioButton.propTypes = {
	type: string.isRequired,
	id: string.isRequired,
	name: string.isRequired,
	label: string,
	description: string,
	value: string,
	required: bool,
	touched: bool,
	setTouched: func.isRequired,
	readOnly: bool,
	disabled: bool,
	errors: array,
	onChange: func.isRequired,
	className: string,
	options: arrayOf(
		shape({
			value: oneOfType([string, number]),
			label: string,
			disabled: bool,
		}),
	).isRequired,
};

export default InputRadioButton;
