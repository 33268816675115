import { array, number, shape, string } from 'prop-types';

import { Col } from 'components/Grid';

const FormatCol = ({
	breakpoint: { sm = 12, md = 6, lg = 4 },
	order: { smOrder = 1, mdOrder = 1, lgOrder = 1 },
	name,
	children,
}) => {
	return (
		<Col
			sm={sm}
			md={md}
			lg={lg}
			smOrder={smOrder}
			mdOrder={mdOrder}
			lgOrder={lgOrder}
			className={name}>
			{children}
		</Col>
	);
};

FormatCol.propTypes = {
	name: string.isRequired,
	breakpoint: shape({
		sm: number,
		md: number,
		lg: number,
	}).isRequired,
	order: shape({
		smOrder: number,
		mdOrder: number,
		lgOrder: number,
	}).isRequired,
	children: array,
};

export default FormatCol;
