import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadFile } from 'core/services/file';
import { FileTypeIconService } from 'core/services/icon';
import { PropTypes } from 'prop-types';

import './Attachments.scss';

export default function Attachments({ attachments = [], links = [] }) {
	const list = [...attachments, ...links];
	return list.length ? (
		<div className='attachments'>
			{list.map(item => (
				<Attachment key={item.id} {...item} />
			))}
		</div>
	) : null;
}

function Attachment({
	mime,
	description,
	filename,
	name,
	url,
	isProof = false,
	canBeUsed,
}) {
	const handleClick = async () => {
		if (url) {
			window.open(url, '_blank', 'rel=noopener noreferrer');
			return;
		}

		downloadFile(filename, mime, description);
	};
	return (
		<div className='attachment' onClick={handleClick}>
			<span>
				<span className='icon'>
					<FontAwesomeIcon
						icon={FileTypeIconService(mime || 'text/uri-list')}
						fixedWidth
					/>
				</span>

				<span className='text'>{description || name}</span>
			</span>
			{!isProof ? (
				canBeUsed ? (
					<FontAwesomeIcon icon={faEye} />
				) : (
					<FontAwesomeIcon icon={faEyeSlash} />
				)
			) : null}
		</div>
	);
}

Attachments.propTypes = {
	attachments: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			description: PropTypes.string,
			filename: PropTypes.string,
			mime: PropTypes.string,
		}),
	),
	links: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			url: PropTypes.string,
			name: PropTypes.string,
		}),
	),
};

Attachment.propTypes = {
	id: PropTypes.number.isRequired,
	description: PropTypes.string,
	filename: PropTypes.string,
	name: PropTypes.string,
	url: PropTypes.string,
	mime: PropTypes.string,
};
