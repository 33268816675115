import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import {
	faBirthdayCake,
	faBriefcase,
	faChalkboardUser,
	faChampagneGlasses,
	faGraduationCap,
	faIdCard,
	faMartiniGlassCitrus,
	faNewspaper,
	faPanorama,
	faSchool,
	faTags,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toShortDate } from 'core/services/date';
import {
	loadStatics,
	loadUserStatic,
} from 'core/store/statics/statics.actions';

import Card from 'components/Card/Card';
import DataTable from 'components/DataTable/DataTable';
import Graphic from 'components/Graphic/Graphic';
import { Col, Row } from 'components/Grid';
import Icon from 'components/Icon/Icon';
import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal/Modal';
import { StatisticOne, StatisticTwo } from 'components/Statistics';

import Visitors from './Visitors';

import './dashboard.scss';

const Dashboard = () => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);

	const today = new Date();

	const {
		statics: {
			next,
			events,
			trainings,
			news,
			instructors,
			tags,
			endpointVisitors,
			invitations,
			stickers,
		},
		mostCount,
		mostEntryCount,
		mostSubFeedCount,
		staticsLoading,
		userStaticsLoading,
		userStatics,
	} = useSelector(state => state.staticsReducer);
	const { permissions } = useSelector(state => state.authReducer);

	useEffect(() => {
		dispatch(loadStatics());
	}, [dispatch]);

	const handleUserDetail = id => {
		dispatch(loadUserStatic(id));
		setShowModal(true);
	};

	return staticsLoading ? (
		<Loading />
	) : (
		<section className='dashboard'>
			<section className='dashboard__shortcuts'>
				<div className='btn__group full--flex'>
					<NavLink
						to='/nieuws/toevoegen'
						className='btn btn--primary'>
						<FontAwesomeIcon icon={faNewspaper} fixedWidth />{' '}
						toevoegen
					</NavLink>
					<NavLink
						to='/evenementen/toevoegen'
						className='btn btn--primary'>
						<FontAwesomeIcon icon={faChampagneGlasses} fixedWidth />{' '}
						toevoegen
					</NavLink>
					<NavLink
						to='/opleidingen/toevoegen'
						className='btn btn--primary'>
						<FontAwesomeIcon icon={faGraduationCap} fixedWidth />{' '}
						toevoegen
					</NavLink>
				</div>
			</section>

			<section className='dashboard__upcoming'>
				<Row>
					{next?.first ? (
						<Col lg={6}>
							<Card title={next?.first.name} withShadow>
								<Link
									to={`/${next?.first.type === 'trainings' ? 'opleidingen' : 'evenementen'}/${next?.first.id}`}>
									<div className='content'>
										<b>Datum:</b>{' '}
										{toShortDate(next?.first.date)}
									</div>
									<div className='content'>
										<b>Uur:</b> {next?.first.startTime} -{' '}
										{next?.first.endTime}
									</div>
									{next?.first.location ? (
										<div className='content'>
											<b>Locatie:</b>{' '}
											<span
												dangerouslySetInnerHTML={{
													__html: next?.first.location.replace(
														', ',
														'<br />',
													),
												}}
											/>
										</div>
									) : null}

									<div className='content'>
										<b>Aanwezig:</b> {next?.first.attendees}
										{next?.first.maxAttendees
											? ` / ${next?.first.maxAttendees}`
											: null}
									</div>
								</Link>
							</Card>
						</Col>
					) : null}

					{next?.other?.length ? (
						<Col lg={6}>
							<Row>
								{next?.other.map(x => (
									<Col lg={4} md={6} sm={6} key={x.id}>
										<Card title={x.name}>
											<Link
												to={`/${x.type === 'trainings' ? 'opleidingen' : 'evenementen'}/${x.id}`}>
												<div className='content'>
													<b>Datum:</b>{' '}
													{toShortDate(x.date)}
												</div>
											</Link>
										</Card>
									</Col>
								))}
							</Row>
						</Col>
					) : (
						<Col lg={next?.first ? 6 : 12}>
							<div
								className='empty-list'
								style={{ margin: '5rem 0' }}>
								Geen volgende evenementen om weer te geven
							</div>
						</Col>
					)}
				</Row>
			</section>

			<section className='dashboard__statics'>
				<Row>
					<Col lg={8}>
						<Row>
							<Col md={6}>
								<StatisticOne
									icon={faMartiniGlassCitrus}
									title={`${events?.usersJoined ?? 0} feestvierders`}
									content={`op ${events?.eventCount ?? 0} evenementen`}
								/>
							</Col>
							<Col md={6}>
								<StatisticOne
									icon={faSchool}
									title={`${trainings?.hoursFollowed ?? 0} gevolgde lesuren`}
									content={`op ${trainings?.totalDays ?? 0} dagen`}
								/>
							</Col>
							<Col md={6}>
								<StatisticOne
									icon={faChalkboardUser}
									title={`${instructors?.total ?? 0} lesgevers`}
									content={`waarvan ${instructors?.active ?? 0} actief`}
								/>
							</Col>
							<Col md={6}>
								<StatisticOne
									icon={faTags}
									title={`${tags?.total ?? 0} keer getagged`}
									content={`${tags?.mostUsed ?? 'onbekend'}`}
								/>
							</Col>
						</Row>
						<Row className='dashboard__upcoming statics'>
							<Col md={6}>
								<Card
									title={
										<>
											<FontAwesomeIcon
												icon={faGraduationCap}
												fixedWidth
											/>{' '}
											Meeste deelnemers
										</>
									}>
									{trainings?.top?.length ? (
										<ol>
											{trainings?.top?.map((x, i) => (
												<li key={x.id}>
													{i + 1}.
													<Link
														to={`/opleidingen/${x.id}`}>
														{x.name}
													</Link>
													<span>
														({x.usersJoined})
													</span>
												</li>
											))}
										</ol>
									) : (
										<div
											className='empty-list'
											style={{
												margin: 0,
												fontSize: '2rem',
											}}>
											Geen data om weer te geven
										</div>
									)}
								</Card>
							</Col>
							<Col md={6}>
								<Card
									title={
										<>
											<FontAwesomeIcon
												icon={faChampagneGlasses}
												fixedWidth
											/>{' '}
											Meeste deelnemers
										</>
									}>
									{events?.top?.length ? (
										<ol>
											{events?.top?.map((x, i) => (
												<li key={x.id}>
													{i + 1}.
													<Link
														to={`/evenementen/${x.id}`}>
														{x.name}
													</Link>
													<span>
														({x.usersJoined})
													</span>
												</li>
											))}
										</ol>
									) : (
										<div
											className='empty-list'
											style={{
												margin: 0,
												fontSize: '2rem',
											}}>
											Geen data om weer te geven
										</div>
									)}
								</Card>
							</Col>
						</Row>
					</Col>
					<Col lg={4}>
						<Row>
							<Col lg={12} md={4}>
								<StatisticTwo
									year={today.getFullYear()}
									type='events'
									count={events?.count ?? 0}
								/>
							</Col>
							<Col lg={12} md={4}>
								<StatisticTwo
									year={today.getFullYear()}
									type='trainings'
									count={trainings?.count ?? 0}
								/>
							</Col>
							<Col lg={12} md={4}>
								<StatisticTwo
									year={today.getFullYear()}
									type='news'
									count={news?.count ?? 0}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</section>

			{permissions.includes('statistics_see') ? (
				<>
					<section className='dashboard__visitors'>
						<Row>
							<Visitors
								type='nieuws'
								icon={faNewspaper}
								visitors={news?.visitors ?? []}
							/>

							<Visitors
								type='nieuws'
								icon={faNewspaper}
								visitors={news?.uniqueVisitors ?? []}
								isUnique
							/>

							<Visitors
								type='evenementen'
								icon={faChampagneGlasses}
								visitors={events?.visitors ?? []}
							/>

							<Visitors
								type='evenementen'
								icon={faChampagneGlasses}
								visitors={events?.uniqueVisitors ?? []}
								isUnique
							/>

							<Visitors
								type='opleidingen'
								icon={faGraduationCap}
								visitors={trainings?.visitors ?? []}
							/>

							<Visitors
								type='opleidingen'
								icon={faGraduationCap}
								visitors={trainings?.uniqueVisitors ?? []}
								isUnique
							/>
						</Row>
					</section>

					<section className='dashboard__stickers'>
						<Card title='Meest gebruikte stickers'>
							<Row>
								<Col lg={4} md={6}>
									<Graphic
										data={
											stickers?.mostUsedStickers?.map(
												x => ({
													base: mostCount,
													value: x.count,
													label: (
														<Icon icon={x.class} />
													),
												}),
											) ?? []
										}
										title='Algemeen'
										name='mostUsedStickers'
										type='bar'
										showLegend
									/>
								</Col>

								<Col lg={4} md={6}>
									<Graphic
										data={
											stickers?.mostEntryStickers?.map(
												x => ({
													base: mostEntryCount,
													value: x.count,
													label: (
														<Icon icon={x.class} />
													),
												}),
											) ?? []
										}
										title='Bij berichten'
										name='mostUsedEntryStickers'
										type='bar'
										showLegend
									/>
								</Col>

								<Col lg={4}>
									<Graphic
										data={
											stickers?.mostSubFeedStickers?.map(
												x => ({
													base: mostSubFeedCount,
													value: x.count,
													label: (
														<Icon icon={x.class} />
													),
												}),
											) ?? []
										}
										title='Bij gebruikersfeed'
										name='mostUsedSubFeedStickers'
										type='bar'
										showLegend
									/>
								</Col>
							</Row>
						</Card>

						<Row className='dashboard__upcoming statics'>
							<Col md={6}>
								<Card title='Berichten met de meeste stickers'>
									{stickers?.entryWithStickers?.length ? (
										<ol>
											{stickers?.entryWithStickers?.map(
												x => (
													<li
														key={`entries-${x.stickerableId}-${x.type}`}>
														<FontAwesomeIcon
															icon={
																x.type ===
																'news'
																	? faNewspaper
																	: x.type ===
																		  'events'
																		? faChampagneGlasses
																		: faGraduationCap
															}
															fixedWidth
														/>
														<Link
															to={`/${x.type === 'news' ? 'nieuws' : x.type === 'events' ? 'evenementen' : 'opleidingen'}/${x.id}`}>
															{x.name}
														</Link>
														<span>({x.count})</span>
													</li>
												),
											)}
										</ol>
									) : (
										<div
											className='empty-list'
											style={{
												margin: 0,
												fontSize: '2rem',
											}}>
											Geen data om weer te geven
										</div>
									)}
								</Card>
							</Col>
							<Col md={6}>
								<Card title='Gebruikersfeed met de meeste stickers'>
									{stickers?.subFeedsWithStickers?.length ? (
										<ol>
											{stickers?.subFeedsWithStickers?.map(
												x => (
													<li
														key={`sub-feed-${x.userId}-${x.type}`}>
														<FontAwesomeIcon
															icon={
																x.type ===
																'profileUpdate'
																	? faIdCard
																	: x.type ===
																		  'pictureUpdate'
																		? faPanorama
																		: x.type ===
																			  'birthday'
																			? faBirthdayCake
																			: faBriefcase
															}
															fixedWidth
														/>
														<div>{x.title}</div>
														<span>({x.count})</span>
													</li>
												),
											)}
										</ol>
									) : (
										<div
											className='empty-list'
											style={{
												margin: 0,
												fontSize: '2rem',
											}}>
											Geen data om weer te geven
										</div>
									)}
								</Card>
							</Col>
						</Row>
					</section>

					<section className='dashboard__table'>
						<Card title='Uitnodigingen per gebruiker'>
							<DataTable
								data={
									invitations?.length
										? invitations?.map(x => ({
												...x,
												name: `${x.lastName} ${x.firstName}`,
												ignored:
													x.invitations -
													+x.confirmed -
													+x.declined,
											}))
										: []
								}
								columns={[
									{ name: 'name', label: 'Naam', sort: true },
									{
										name: 'invitations',
										label: 'Uitgenodigd',
										className: 'center',
										sort: true,
									},
									{
										name: 'confirmed',
										label: 'Bevestigd',
										className: 'center',
										sort: true,
									},
									{
										name: 'declined',
										label: 'Geweigerd',
										className: 'center',
										sort: true,
									},
									{
										name: 'ignored',
										label: 'Genegeerd',
										className: 'center',
										sort: true,
									},
									{
										name: 'actions',
										label: '',
										sort: false,
										actions: {
											view: (index, id) =>
												handleUserDetail(id),
										},
										className: 'right',
									},
								]}
								paginate
								search
							/>
						</Card>
					</section>

					<section className='dashboard__table'>
						<Card title='Bezoekers per URL'>
							<DataTable
								data={
									endpointVisitors?.length
										? endpointVisitors?.map((x, i) => ({
												...x,
												number: i + 1,
												url: x.endpoint,
												endpoint: (
													<a
														href={`https://ketchup.unipartners.org${x.endpoint}`}
														target='_blank'
														rel='noreferrer'>
														{x.endpoint}
													</a>
												),
											}))
										: []
								}
								columns={[
									{ name: 'number', label: '#' },
									{ name: 'endpoint', label: 'Url' },
									{
										name: 'count',
										label: 'Bezoekers',
										className: 'right',
									},
								]}
								paginate
								search
							/>
						</Card>
					</section>

					<Modal
						show={showModal}
						close={() => setShowModal(!showModal)}
						large>
						{userStaticsLoading ? (
							<Loading />
						) : (
							<DataTable
								data={
									userStatics?.length
										? userStatics?.map((x, i) => ({
												...x,
												number: i + 1,
												name: x.name,
												confirmed:
													x.confirmed !== null
														? x.confirmed
															? 'Bevestigd'
															: 'Geweigerd'
														: 'Genegeerd',
											}))
										: []
								}
								columns={[
									{ name: 'number', label: '#' },
									{ name: 'name', label: 'Naam' },
									{
										name: 'confirmed',
										label: 'Status',
										className: 'right',
									},
								]}
							/>
						)}
					</Modal>
				</>
			) : null}
		</section>
	);
};

export default Dashboard;
