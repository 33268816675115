import { useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toShortDate } from 'core/services/date';
import { isValid, parse } from 'date-fns';
import { any, array, arrayOf, bool, func, oneOfType, string } from 'prop-types';

import { Button } from 'components/Form';
import Modal from 'components/Modal/Modal';

import UiInput from '../UiInput/UiInput';

const DatepickerInput = ({
	id,
	name,
	label = '',
	description = '',
	value = '',
	required = false,
	focused = false,
	setFocused,
	touched = false,
	setTouched,
	readOnly = false,
	disabled = false,
	autocomplete = false,
	errors = [],
	onChange,
	className,
	disabledDays = [],
}) => {
	const [selected, setSelected] = useState(
		value ? parse(value, 'dd-MM-y', new Date()) : null,
	);
	const [inputValue, setInputValue] = useState(value || null);
	const [isPopperOpen, setIsPopperOpen] = useState(false);

	//const popperRef = useRef(null);
	const buttonRef = useRef(null);
	// eslint-disable-next-line no-unused-vars
	const [popperElement, setPopperElement] = useState(null);

	const handleButtonClick = () => {
		setIsPopperOpen(!isPopperOpen);
	};

	const closePopper = () => {
		setIsPopperOpen(false);
		buttonRef?.current?.focus();
	};

	const handleInputChange = e => {
		setInputValue(e.target.value);

		const date = parse(e.target.value, 'dd-MM-y', new Date());

		if (isValid(date)) {
			setSelected(date);
		} else {
			setSelected(undefined);
		}

		onChange(e.target.value);
	};

	const handleDaySelect = date => {
		setTouched(true);
		setSelected(date);

		if (date) {
			setInputValue(`${toShortDate(date)}`);
			closePopper();
		} else {
			setInputValue('');
		}

		onChange(date ? toShortDate(date) : null);
	};

	return (
		<UiInput
			id={id}
			label={label}
			description={description}
			required={required}
			focused={focused}
			disabled={disabled || readOnly}
			className={className}
			hasValue={inputValue?.length > 0}
			hasError={errors.length > 0 && touched ? true : false}
			errors={errors}>
			<input
				type='text'
				name={name}
				id={id}
				value={inputValue ?? ''}
				placeholder='dd-mm-yyyy'
				autoComplete={autocomplete ? autocomplete : 'off'}
				onChange={e => handleInputChange(e)}
				onBlur={() => {
					setTouched(true);
					setFocused(false);
				}}
				onFocus={() => setFocused(true)}
				readOnly={readOnly}
				disabled={disabled}
			/>
			<Button
				buttonStyle='primary'
				type='button'
				label={
					<FontAwesomeIcon icon={faCalendar} size='lg' fixedWidth />
				}
				onClick={handleButtonClick}
				className='form-field__control__action'
				disabled={disabled}
				ariaLabel='Open datepicker'
			/>
			{isPopperOpen ? (
				<Modal
					show={isPopperOpen}
					close={closePopper}
					title='Kies een datum'
					style={{ width: 'auto' }}>
					<div
						tabIndex={-1}
						className='dialog-sheet'
						ref={setPopperElement}
						role='dialog'
						aria-label='DayPicker calendar'>
						<DayPicker
							initialFocus={isPopperOpen}
							mode='single'
							defaultMonth={selected}
							selected={selected}
							onSelect={handleDaySelect}
							disabled={disabledDays}
						/>
					</div>
				</Modal>
			) : null}
		</UiInput>
	);
};

DatepickerInput.propTypes = {
	id: string,
	name: string.isRequired,
	label: string,
	description: string,
	value: oneOfType([string, arrayOf(string)]),
	className: string,
	required: bool,
	focused: bool,
	setFocused: func,
	touched: bool,
	setTouched: func,
	readOnly: bool,
	disabled: bool,
	autocomplete: any,
	validations: array,
	errors: array,
	onChange: func.isRequired,
	disabledDays: oneOfType([array, bool]),
};

export default DatepickerInput;
