import React from 'react';
import ReactPaginate from 'react-paginate';

import './Pagination.scss';

export const Pagination = ({ from, to, total, pageCount, paginate }) => {
	return (
		<div className='pagination'>
			<div className='pagination__info'>
				<b>
					{from} - {to}
				</b>{' '}
				van {total}
			</div>

			<ReactPaginate
				initialPage={0}
				onPageChange={paginate}
				pageCount={pageCount || 1}
				pageRangeDisplayed={5}
				marginPagesDisplayed={3}
				previousLabel={<span>&lt; vorige</span>}
				nextLabel={<span>volgende &gt;</span>}
				containerClassName='pagination__pages'
			/>
		</div>
	);
};
